import { HTMLAttributes } from 'react'

import { ThemeColors } from '../theme/colors'

import { AddBigIcon } from './components/AddBigIcon'
import { AddIcon } from './components/AddIcon'
import { AddInstanceIcon } from './components/AddInstanceIcon'
import { AddSmallIcon } from './components/AddSmallIcon'
import { AiIcon } from './components/AiIcon'
import { AndroidLogoIcon } from './components/AndroidLogoIcon'
import { AppleIcon } from './components/AppleIcon'
import { AppleLogoIcon } from './components/AppleLogoIcon'
import { AppleLogoRoundedIcon } from './components/AppleLogoRoundedIcon'
import { AppleWatchDisconnectedIcon } from './components/AppleWatchDisconnectedIcon'
import { AppOfTheDayIcon } from './components/AppOfTheDayIcon'
import { AppstoreBadgeDarkIcon } from './components/AppstoreBadgeDarkIcon'
import { AppstoreBadgeIcon } from './components/AppstoreBadgeIcon'
import { ArcheryTargetIcon } from './components/ArcheryTargetIcon'
import { ArrowBigLeftIcon } from './components/ArrowBigLeftIcon'
import { ArrowBigRightIcon } from './components/ArrowBigRightIcon'
import { ArrowDoubleBigLeftIcon } from './components/ArrowDoubleBigLeftIcon'
import { ArrowDoubleBigRightIcon } from './components/ArrowDoubleBigRightIcon'
import { ArrowDownIcon } from './components/ArrowDownIcon'
import { ArrowFeedbackDownIcon } from './components/ArrowFeedbackDownIcon'
import { ArrowFeedbackUpIcon } from './components/ArrowFeedbackUpIcon'
import { ArrowLeftIcon } from './components/ArrowLeftIcon'
import { ArrowRightIcon } from './components/ArrowRightIcon'
import { ArrowSmallDownIcon } from './components/ArrowSmallDownIcon'
import { ArrowSmallLeftIcon } from './components/ArrowSmallLeftIcon'
import { ArrowSmallRightIcon } from './components/ArrowSmallRightIcon'
import { BalanceIcon } from './components/BalanceIcon'
import { BarbellToolIcon } from './components/BarbellToolIcon'
import { BedroomIcon } from './components/BedroomIcon'
import { BicepFillIcon } from './components/BicepFillIcon'
import { BicepIcon } from './components/BicepIcon'
import { Bin } from './components/Bin'
import { BodyBuilderIcon } from './components/BodyBuilderIcon'
import { BosuToolIcon } from './components/BosuToolIcon'
import { ButtonPause } from './components/ButtonPause'
import { ButtonPlay } from './components/ButtonPlay'
import { ButtonRecord } from './components/ButtonRecord'
import { ButtonStop } from './components/ButtonStop'
import { Calendar2 } from './components/Calendar2'
import { CalendarDate } from './components/CalendarDate'
import { CalendarEvent } from './components/CalendarEvent'
import { CalendarRepeat } from './components/CalendarRepeat'
import { CaloriesIcon } from './components/CaloriesIcon'
import { CCheck } from './components/CCheck'
import { ChartBarIcon } from './components/ChartBarIcon'
import { CheckboxCheckedIcon } from './components/CheckboxCheckedIcon'
import { CheckboxIcon } from './components/CheckboxIcon'
import { CheckIcon } from './components/CheckIcon'
import { ChevronDownIcon } from './components/ChevronDownIcon'
import { ChevronUpIcon } from './components/ChevronUpIcon'
import { CircleWarningIcon } from './components/CircleWarningIcon'
import { ClockAlarmIcon } from './components/ClockAlarmIcon'
import { ClockBigIcon } from './components/ClockBig'
import { ClockIcon } from './components/ClockIcon'
import { CommentIcon } from './components/CommentIcon'
import { CopyIcon } from './components/CopyIcon'
import { CrossIcon } from './components/CrossIcon'
import { CrossBigIcon } from './components/CrossIconBig'
import { CrossSmallIcon } from './components/CrossSmallIcon'
import { CustomEffortIcon } from './components/CustomEffortIcon'
import { DataUploadIcon } from './components/DataUploadIcon'
import { DistanceIcon } from './components/DistanceIcon'
import { DumbbellToolIcon } from './components/DumbbellToolIcon'
import { EditIcon } from './components/EditIcon'
import { ExchangeIcon } from './components/ExchangeIcon'
import { FacebookIcon } from './components/FacebookIcon'
import { FeaturedByAppleIcon } from './components/FeaturedByAppleIcon'
import { FilterIcon } from './components/FilterIcon'
import { FireIcon } from './components/FireIcon'
import { FitifyHcLogoPictogram } from './components/FitifyHcLogoPictogram'
import { FitifyLogoIcon } from './components/FitifyLogoIcon'
import { FitifyLogoTemporaryIcon } from './components/FitifyLogoTemporaryIcon'
import { FlameIcon } from './components/FlameIcon'
import { FlashIcon } from './components/FlashIcon'
import { FoamRollerToolIcon } from './components/FoamRollerToolIcon'
import { GooglePlayBadgeDarkIcon } from './components/GooglePlayBadgeDarkIcon'
import { GooglePlayBadgeIcon } from './components/GooglePlayBadgeIcon'
import { GymClassIcon } from './components/GymClassIcon'
import { Hamburger } from './components/Hamburger'
import { HeartbeatIcon } from './components/HeartbeatIcon'
import { HeartBrokenIcon } from './components/HeartBrokenIcon'
import { HeartIcon } from './components/HeartIcon'
import { HeartOutlineIcon } from './components/HeartOutlineIcon'
import { HeartRateVariabilityIcon } from './components/HeartRateVariabilityIcon'
import { InfoIcon } from './components/InfoIcon'
import { InstagramIcon } from './components/InstagramIcon'
import { IntensityBitEasyIcon } from './components/IntensityBitEasyIcon'
import { IntensityBitHardIcon } from './components/IntensityBitHardIcon'
import { IntensityPerfectIcon } from './components/IntensityPerfectIcon'
import { IntensityTooEasyIcon } from './components/IntensityTooEasyIcon'
import { IntensityTooHardIcon } from './components/IntensityTooHardIcon'
import { InterviewIcon } from './components/InterviewIcon'
import { JumpingJackIcon } from './components/JumpingJackIcon'
import { JumpRopeIcon } from './components/JumpRopeIcon'
import { KettlebellToolIcon } from './components/KettlebellToolIcon'
import { LatStationToolIcon } from './components/LatStationToolIcon'
import { LaunchAppIcon } from './components/LaunchAppIcon'
import { Loader } from './components/Loader'
import { LocalFireDepartmentIcon } from './components/LocalFireDepartmentIcon'
import { LotusIcon } from './components/LotusIcon'
import { LungsIcon } from './components/LungsIcon'
import { MagnifierIcon } from './components/MagnifierIcon'
import { MaleIcon } from './components/MaleIcon'
import { Man23Icon } from './components/Man23Icon'
import { MedicineBallToolIcon } from './components/MedicineBallToolIcon'
import { MeetingIcon } from './components/MeetingIcon'
import { MenuCollapsedIcon } from './components/MenuCollapsedIcon'
import { MenuOpenIcon } from './components/MenuOpenIcon'
import { MenuThreeDots } from './components/MenuThreeDots'
import { MessageWithIcon } from './components/MessageIcon'
import { MessageWithNotificationIcon } from './components/MessageWithNotificationIcon'
import { MessengerLogoIcon } from './components/MessengerLogoIcon'
import { MetricsIcon } from './components/MetricsIcon'
import { Microphone } from './components/Microphone'
import { MicrophoneOutline } from './components/MicrophoneOutline'
import { MissingAvatarIcon } from './components/MissingAvatarIcon'
import { MissingIcon } from './components/MissingIcon'
import { MoonIcon } from './components/MoonIcon'
import { NoEffortIcon } from './components/NoEffortIcon'
import { NoteIcon } from './components/NoteIcon'
import { NoteTimeIcon } from './components/NoteTimeIcon'
import { NotionIcon } from './components/NotionIcon'
import { PencilFillIcon } from './components/PencilFillIcon'
import { PencilIcon } from './components/PencilIcon'
import { PersonalTrainerIcon } from './components/PersonalTrainerIcon'
import { PinterestIcon } from './components/PinterestIcon'
import { PlayIcon } from './components/PlayIcon'
import { PlusIcon } from './components/PlusIcon'
import { PrivacyPolicy } from './components/PrivacyPolicy'
import { PullUpBarToolIcon } from './components/PullUpBarToolIcon'
import { QuotesIcon } from './components/QuotesIcon'
import { Refresh } from './components/Refresh'
import { RemoveIcon } from './components/RemoveIcon'
import { RepsIcon } from './components/RepsIcon'
import { ResistanceBandToolIcon } from './components/ResistanceBandToolIcon'
import { RunningShoe } from './components/RunningShoe'
import { SadFaceIcon } from './components/SadFaceIcon'
import { SandbagToolIcon } from './components/SandbagToolIcon'
import { SignalIcon } from './components/SignalIcon'
import { SleepIcon } from './components/SleepIcon'
import { SpaceshipIcon } from './components/SpaceshipIcon'
import { SpotifyIcon } from './components/SpotifyIcon'
import { StandIcon } from './components/StandIcon'
import { StarFullIcon } from './components/StarFullIcon'
import { StarIcon } from './components/StarIcon'
import { StatusCompletedIcon } from './components/StatusCompletedIcon'
import { StatusCrossIcon } from './components/StatusCrossIcon'
import { StatusOkIcon } from './components/StatusOkIcon'
import { StatusPlannedCompletedIcon } from './components/StatusPlannedCompletedIcon'
import { StatusWarningIcon } from './components/StatusWarning'
import { StepsIcon } from './components/StepsIcon'
import { StopwatchIcon } from './components/StopwatchIcon'
import { StopwatchOutlineIcon } from './components/StopwatchOutlineIcon'
import { StravaLogoRoundedIcon } from './components/StravaLogoRoundedIcon'
import { StretchingIcon } from './components/StretchingIcon'
import { SunIcon } from './components/SunIcon'
import { SwissBallToolIcon } from './components/SwissBallToolIcon'
import { TeamIcon } from './components/TeamIcon'
import { ThumbsDownIcon } from './components/ThumbsDown'
import { ThumbsUpIcon } from './components/ThumbsUp'
import { TimesIcon } from './components/TimesIcon'
import { TooltipIcon } from './components/TooltipIcon'
import { TrashBinIcon } from './components/TrashBinIcon'
import { TrashBinIconV2 } from './components/TrashBinIconV2'
import { TriangleSmallDown } from './components/TriangleSmallDown'
import { TriangleSmallUp } from './components/TriangleSmallUp'
import { Trophy } from './components/Trophy'
import { TrxToolIcon } from './components/TrxToolIcon'
import { UserIcon } from './components/UserIcon'
import { UserPinIcon } from './components/UserPinIcon'
import { WarningIcon } from './components/WarningIcon'
import { WarningSignIcon } from './components/WarningSignIcon'
import { WaterIntakeIcon } from './components/WaterIntakeIcon'
import { WeightIcon } from './components/WeightIcon'
import { WeightScaleIcon } from './components/WeightScaleIcon'
import { WorkoutPlanIcon } from './components/WorkoutPlanIcon'
import { XSocialIcon } from './components/XSocialIcon'
import { YogaIcon } from './components/YogaIcon'
import { IconTypes } from './Icon.Types'

export interface IconProps extends HTMLAttributes<SVGAElement> {
  name?: IconTypes.Variants | null
  // TODO: Fix once we have styled components in landing and onboarding packages
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  color?: ThemeColors | string
}
const Icon = ({ name, color, ...rest }: IconProps): JSX.Element => {
  const iconProps = {
    color: color && color,
    ...rest,
  }

  const renderIcon = (name: IconTypes.Variants): JSX.Element => {
    switch (name) {
      case 'jump-rope':
        return <JumpRopeIcon {...iconProps} />
      case 'meeting':
        return <MeetingIcon {...iconProps} />
      case 'workout-plan':
        return <WorkoutPlanIcon {...iconProps} />
      case 'interview':
        return <InterviewIcon {...iconProps} />
      case 'personal-trainer':
        return <PersonalTrainerIcon {...iconProps} />
      case 'appstore-badge':
        return <AppstoreBadgeIcon {...iconProps} />
      case 'appstore-badge-dark':
        return <AppstoreBadgeDarkIcon {...iconProps} />
      case 'googleplay-badge':
        return <GooglePlayBadgeIcon {...iconProps} />
      case 'googleplay-badge-dark':
        return <GooglePlayBadgeDarkIcon {...iconProps} />
      case 'clock':
        return <ClockIcon {...iconProps} />
      case 'fire':
        return <FireIcon {...iconProps} />
      case 'flame':
        return <FlameIcon {...iconProps} />
      case 'jumping-jack':
        return <JumpingJackIcon {...iconProps} />
      case 'missing-avatar':
        return <MissingAvatarIcon {...iconProps} />
      case 'trash-bin':
        return <TrashBinIcon {...iconProps} />
      case 'message-with-notification':
        return <MessageWithNotificationIcon {...iconProps} />
      case 'message':
        return <MessageWithIcon {...iconProps} />
      case 'reps':
        return <RepsIcon {...iconProps} />
      case 'heart-outline':
        return <HeartOutlineIcon {...iconProps} />
      case 'thumbs-up':
        return <ThumbsUpIcon {...iconProps} />
      case 'thumbs-down':
        return <ThumbsDownIcon {...iconProps} />
      case 'arrow-small-left':
        return <ArrowSmallLeftIcon {...iconProps} />
      case 'arrow-small-right':
        return <ArrowSmallRightIcon {...iconProps} />
      case 'arrow-left':
        return <ArrowLeftIcon {...iconProps} />
      case 'add-instance':
        return <AddInstanceIcon {...iconProps} />
      case 'arrow-right':
        return <ArrowRightIcon {...iconProps} />
      case 'arrow-down':
        return <ArrowDownIcon {...iconProps} />
      case 'arrow-up':
        return <ArrowDownIcon {...iconProps} transform="rotate(180)" />
      case 'instagram':
        return <InstagramIcon {...iconProps} />
      case 'facebook':
        return <FacebookIcon {...iconProps} />
      case 'pinterest':
        return <PinterestIcon {...iconProps} />
      case 'spotify':
        return <SpotifyIcon {...iconProps} />
      case 'x-social':
        return <XSocialIcon {...iconProps} />
      case 'times':
        return <TimesIcon {...iconProps} />
      case 'plus':
        return <PlusIcon {...iconProps} />
      case 'circle-warning':
        return <CircleWarningIcon {...iconProps} />
      case 'star-full':
        return <StarFullIcon {...iconProps} />
      case 'fitify-logo':
        return <FitifyLogoIcon {...iconProps} />
      case 'fitify-logo-temporary':
        return <FitifyLogoTemporaryIcon {...iconProps} />
      case 'fitify-hc-logo-pictogram':
        return <FitifyHcLogoPictogram {...iconProps} />
      case 'clock-alarm':
        return <ClockAlarmIcon {...iconProps} />
      case 'heart':
        return <HeartIcon {...iconProps} />
      case 'balance':
        return <BalanceIcon {...iconProps} />
      case 'sleep':
        return <SleepIcon {...iconProps} />
      case 'weight':
        return <WeightIcon {...iconProps} />
      case 'water-intake':
        return <WaterIntakeIcon {...iconProps} />
      case 'cross':
        return <CrossIcon {...iconProps} />
      case 'cross-big':
        return <CrossBigIcon {...iconProps} />
      case 'calories':
        return <CaloriesIcon {...iconProps} />
      case 'stand':
        return <StandIcon {...iconProps} />
      case 'apple-watch-disconnected':
        return <AppleWatchDisconnectedIcon {...iconProps} />
      case 'signal':
        return <SignalIcon {...iconProps} />
      case 'steps':
        return <StepsIcon {...iconProps} />
      case 'magnifier':
        return <MagnifierIcon {...iconProps} />
      case 'checkbox':
        return <CheckboxIcon {...iconProps} />
      case 'checkbox-checked':
        return <CheckboxCheckedIcon {...iconProps} />
      case 'status-cross':
        return <StatusCrossIcon {...iconProps} />
      case 'status-ok':
        return <StatusOkIcon {...iconProps} />
      case 'status-warning':
        return <StatusWarningIcon {...iconProps} />
      case 'clock-big':
        return <ClockBigIcon {...iconProps} />
      case 'edit':
        return <EditIcon {...iconProps} />
      case 'cross-small':
        return <CrossSmallIcon {...iconProps} />
      case 'note':
        return <NoteIcon {...iconProps} />
      case 'chevron-up':
        return <ChevronUpIcon {...iconProps} />
      case 'chevron-down':
        return <ChevronDownIcon {...iconProps} />
      case 'menu-collapsed':
        return <MenuCollapsedIcon {...iconProps} />
      case 'menu-open':
        return <MenuOpenIcon {...iconProps} />
      case 'barbell-tool':
        return <BarbellToolIcon {...iconProps} />
      case 'bosu-tool':
        return <BosuToolIcon {...iconProps} />
      case 'dumbbell-tool':
        return <DumbbellToolIcon {...iconProps} />
      case 'foamroller-tool':
        return <FoamRollerToolIcon {...iconProps} />
      case 'kettlebell-tool':
        return <KettlebellToolIcon {...iconProps} />
      case 'medicineball-tool':
        return <MedicineBallToolIcon {...iconProps} />
      case 'pullupbar-tool':
        return <PullUpBarToolIcon {...iconProps} />
      case 'resistanceband-tool':
        return <ResistanceBandToolIcon {...iconProps} />
      case 'swissball-tool':
        return <SwissBallToolIcon {...iconProps} />
      case 'trx-tool':
        return <TrxToolIcon {...iconProps} />
      case 'add-small':
        return <AddSmallIcon {...iconProps} />
      case 'add-big':
        return <AddBigIcon {...iconProps} />
      case 'user-icon':
        return <UserIcon {...iconProps} />
      case 'tooltip':
        return <TooltipIcon {...iconProps} />
      case 'sad-face':
        return <SadFaceIcon {...iconProps} />
      case 'pencil':
        return <PencilIcon {...iconProps} />
      case 'distance':
        return <DistanceIcon {...iconProps} />
      case 'custom-effort':
        return <CustomEffortIcon {...iconProps} />
      case 'no-effort':
        return <NoEffortIcon {...iconProps} />
      case 'c-remove':
        return <RemoveIcon {...iconProps} />
      case 'c-warning':
        return <WarningIcon {...iconProps} />
      case 'play':
        return <PlayIcon {...iconProps} />
      case 'c-info':
        return <InfoIcon {...iconProps} />
      case 'trash-bin-v2':
        return <TrashBinIconV2 {...iconProps} />
      case 'bicep':
        return <BicepIcon {...iconProps} />
      case 'stretching':
        return <StretchingIcon {...iconProps} />
      case 'star':
        return <StarIcon {...iconProps} />
      case 'sandbag-tool':
        return <SandbagToolIcon {...iconProps} />
      case 'latstation-tool':
        return <LatStationToolIcon {...iconProps} />
      case 'add':
        return <AddIcon {...iconProps} />
      case 'hrv':
        return <HeartRateVariabilityIcon {...iconProps} />
      case 'stopwatch':
        return <StopwatchIcon {...iconProps} />
      case 'intensity-too-easy':
        return <IntensityTooEasyIcon {...iconProps} />
      case 'intensity-bit-easy':
        return <IntensityBitEasyIcon {...iconProps} />
      case 'intensity-perfect':
        return <IntensityPerfectIcon {...iconProps} />
      case 'intensity-bit-hard':
        return <IntensityBitHardIcon {...iconProps} />
      case 'intensity-too-hard':
        return <IntensityTooHardIcon {...iconProps} />
      case 'comment':
        return <CommentIcon {...iconProps} />
      case 'status-completed':
        return <StatusCompletedIcon {...iconProps} />
      case 'status-planned-completed':
        return <StatusPlannedCompletedIcon {...iconProps} />
      case 'yoga':
        return <YogaIcon {...iconProps} />
      case 'apple':
        return <AppleIcon {...iconProps} />
      case 'arrow-feedback-down':
        return <ArrowFeedbackDownIcon {...iconProps} />
      case 'arrow-feedback-up':
        return <ArrowFeedbackUpIcon {...iconProps} />
      case 'team':
        return <TeamIcon {...iconProps} />
      case 'spaceship':
        return <SpaceshipIcon {...iconProps} />
      case 'user-pin':
        return <UserPinIcon {...iconProps} />
      case 'lotus':
        return <LotusIcon {...iconProps} />
      case 'flash':
        return <FlashIcon {...iconProps} />
      case 'exchange':
        return <ExchangeIcon {...iconProps} />
      case 'chart-bar':
        return <ChartBarIcon {...iconProps} />
      case 'metrics':
        return <MetricsIcon {...iconProps} />
      case 'gym-class':
        return <GymClassIcon {...iconProps} />
      case 'hamburger':
        return <Hamburger {...iconProps} />
      case 'privacy-policy':
        return <PrivacyPolicy {...iconProps} />
      case 'calendar-event':
        return <CalendarEvent {...iconProps} />
      case 'trophy':
        return <Trophy {...iconProps} />
      case 'running-shoe':
        return <RunningShoe {...iconProps} />
      case 'calendar-repeat':
        return <CalendarRepeat {...iconProps} />
      case 'bin':
        return <Bin {...iconProps} />
      case 'button-pause':
        return <ButtonPause {...iconProps} />
      case 'button-play':
        return <ButtonPlay {...iconProps} />
      case 'button-record':
        return <ButtonRecord {...iconProps} />
      case 'button-stop':
        return <ButtonStop {...iconProps} />
      case 'c-check':
        return <CCheck {...iconProps} />
      case 'microphone':
        return <Microphone {...iconProps} />
      case 'microphone-outline':
        return <MicrophoneOutline {...iconProps} />
      case 'refresh':
        return <Refresh {...iconProps} />
      case 'copy':
        return <CopyIcon {...iconProps} />
      case 'ai':
        return <AiIcon {...iconProps} />
      case 'loader':
        return <Loader {...iconProps} />
      case 'featured-by-apple':
        return <FeaturedByAppleIcon {...iconProps} />
      case 'app-of-the-day':
        return <AppOfTheDayIcon {...iconProps} />
      case 'calendar-date':
        return <CalendarDate {...iconProps} />
      case 'pencil-fill':
        return <PencilFillIcon {...iconProps} />
      case 'note-time':
        return <NoteTimeIcon {...iconProps} />
      case 'stopwatch-outline':
        return <StopwatchOutlineIcon {...iconProps} />
      case 'warning-sign':
        return <WarningSignIcon {...iconProps} />
      case 'heart-broken':
        return <HeartBrokenIcon {...iconProps} />
      case 'arrow-small-down':
        return <ArrowSmallDownIcon {...iconProps} />
      case 'man-23':
        return <Man23Icon {...iconProps} />
      case 'launch-app':
        return <LaunchAppIcon {...iconProps} />
      case 'notion':
        return <NotionIcon {...iconProps} />
      case 'sun':
        return <SunIcon {...iconProps} />
      case 'moon':
        return <MoonIcon {...iconProps} />
      case 'bicep-fill':
        return <BicepFillIcon {...iconProps} />
      case 'heartbeat':
        return <HeartbeatIcon {...iconProps} />
      case 'apple-logo':
        return <AppleLogoIcon {...iconProps} />
      case 'android-logo':
        return <AndroidLogoIcon {...iconProps} />
      case 'archery-target':
        return <ArcheryTargetIcon {...iconProps} />
      case 'weight-scale':
        return <WeightScaleIcon {...iconProps} />
      case 'messenger-logo':
        return <MessengerLogoIcon {...iconProps} />
      case 'body-builder':
        return <BodyBuilderIcon {...iconProps} />
      case 'arrow-big-left':
        return <ArrowBigLeftIcon {...iconProps} />
      case 'arrow-big-right':
        return <ArrowBigRightIcon {...iconProps} />
      case 'arrow-double-big-left':
        return <ArrowDoubleBigLeftIcon {...iconProps} />
      case 'arrow-double-big-right':
        return <ArrowDoubleBigRightIcon {...iconProps} />
      case 'quotes':
        return <QuotesIcon {...iconProps} />
      case 'bedroom':
        return <BedroomIcon {...iconProps} />
      case 'local-fire-department':
        return <LocalFireDepartmentIcon {...iconProps} />
      case 'lungs':
        return <LungsIcon {...iconProps} />
      case 'male':
        return <MaleIcon {...iconProps} />
      case 'strava-logo-rounded':
        return <StravaLogoRoundedIcon {...iconProps} />
      case 'apple-logo-rounded':
        return <AppleLogoRoundedIcon {...iconProps} />
      case 'check':
        return <CheckIcon {...iconProps} />
      case 'triangle-sm-down':
        return <TriangleSmallDown {...iconProps} />
      case 'triangle-sm-up':
        return <TriangleSmallUp {...iconProps} />
      case 'menu-three-dots':
        return <MenuThreeDots {...iconProps} />
      case 'filter':
        return <FilterIcon {...iconProps} />
      case 'data-upload':
        return <DataUploadIcon {...iconProps} />
      case 'calendar-2':
        return <Calendar2 {...iconProps} />

      default:
        return <MissingIcon {...iconProps} />
    }
  }
  return name ? renderIcon(name) : <MissingIcon {...iconProps} />
}

export default Icon
