import { useEffect, useState } from 'react'

export const useDeviceSize = () => {
  const [windowSize, setWindowSize] = useState({
    windowWidth: 0,
    windowHeight: 0,
  })
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      })
    }
    const handleOrientationChange = (e: Event) => {
      const target = e.target as ScreenOrientation | null
      const isPortrait =
        target &&
        (target.type === 'portrait-primary' ||
          target.type === 'portrait-secondary')

      setWindowSize({
        windowWidth: isPortrait ? screen.availWidth : screen.availHeight,
        windowHeight: isPortrait ? screen.availHeight : screen.availWidth,
      })
    }

    window.addEventListener('resize', handleResize)
    handleResize()
    if (window.screen.orientation) {
      window.screen.orientation.addEventListener(
        'change',
        handleOrientationChange
      )
    }
    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('resize', handleResize)
      if (window.screen.orientation) {
        window.screen.orientation.removeEventListener(
          'change',
          handleOrientationChange
        )
      }
    }
  }, [])
  return windowSize
}
