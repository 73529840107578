import styled from 'styled-components'

import { colors, ThemeColors } from '../theme/colors'

export const StyledIcon = styled.svg<{ color?: ThemeColors; hover: boolean }>`
  position: relative;

  color: ${(props) => (props.color ? colors[props.color] : 'initial')};
  ${(props) => (props.hover ? 'cursor: pointer;' : null)};
`
