import { useDeviceSize } from 'fitify-ui'
import { ThemeVariants } from 'fitify-ui/src/theme'
import { LinkTypes } from 'fitify-ui-landing/src/@types'
import { ELEMENT_IDS } from 'fitify-ui-landing/src/@types/constants'
import { size } from 'fitify-ui-landing/src/theme/breakpoints'
import { t } from 'i18next'
import dynamic from 'next/dynamic'
import { useTheme } from 'styled-components'

import {
  StyledNavigation,
  StyledNavigationContainer,
  StyledNavigationLogoLink,
} from './Navigation.Styled'
import NavigationMenu, { NavigationVariant } from './NavigationMenu'

const NavigationMobile = dynamic(() => import('./NavigationMobile'))

interface NavigationProps {
  items: LinkTypes.Navigation[]
  logo?: React.ReactNode
  options?: {
    mobileEnabled?: boolean
  }
}

const Navigation = ({ items, logo, options }: NavigationProps) => {
  const { windowWidth } = useDeviceSize()
  const isPhoneBreakpoint = windowWidth <= size.tabletPortraitUpperBoundary
  const theme = useTheme()
  const isDigital = theme.variant === ThemeVariants.DIGITAL

  const mobileEnabled = isPhoneBreakpoint && options?.mobileEnabled

  return (
    <StyledNavigation id={ELEMENT_IDS.navigation}>
      <StyledNavigationContainer>
        <StyledNavigationLogoLink
          href="/"
          aria-label={
            isDigital
              ? t('aria_label_go_to_homepage')
              : t('hc_aria_label_go_to_homepage')
          }
        >
          {logo}
        </StyledNavigationLogoLink>

        {mobileEnabled ? (
          <NavigationMobile items={items} />
        ) : (
          <NavigationMenu
            items={items}
            variant={NavigationVariant.NAVIGATION}
          />
        )}
      </StyledNavigationContainer>
    </StyledNavigation>
  )
}

export default Navigation
