import { StyledIcon } from '../Icon.Styled'

export const ClockIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 4C10.022 4 8.089 4.586 6.444 5.685 4.8 6.784 3.518 8.346 2.761 10.173 2.004 12 1.806 14.011 2.192 15.951 2.578 17.891 3.53 19.673 4.929 21.071 6.327 22.47 8.109 23.422 10.049 23.808 11.989 24.194 14 23.996 15.827 23.239 17.654 22.482 19.216 21.2 20.315 19.556 21.414 17.911 22 15.978 22 14 22 11.348 20.946 8.804 19.071 6.929 17.196 5.054 14.652 4 12 4V4ZM17 15H11V9H13V13H17V15ZM16 0H8V2H16V0Z"
    />
  </StyledIcon>
)
